import { UserUsageData } from '../../../interfaces/usage';
import { createNonPersistentStore } from '../../../lib/zustand';

interface UsageState {
  userData: UserUsageData;
  userHasReadAtLeastOneItem: boolean;
}

const initialState: UsageState = {
  userData: {
    android: null,
    ios: null,
    webApp: null,
    chromeExtension: null,
    safariExtension: null,
    macApp: null
  },
  userHasReadAtLeastOneItem: false
};

export const useUsageStore = createNonPersistentStore<UsageState>(() => initialState, {
  isListeningScreenStore: false
});

export const usageStoreSelectors = {
  getChromeExtensionInstalled: (state: UsageState) => {
    return Boolean(state.userData.chromeExtension);
  },
  getUserData: (state: UsageState) => {
    return state.userData;
  },
  getUserHasReadAtLeastOneItem: (state: UsageState) => {
    return state.userHasReadAtLeastOneItem;
  }
};
