import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import type { ExtensionSettings, IUser } from 'interfaces';
import { pick } from 'lodash';
import type { AppDispatch } from 'store';

import * as speechify from 'lib/speechify';
import { actions as personalVoicesActions } from 'store/personalVoices';

export const personalVoicesMiddlewareOnUserUpdate = async (dispatch: AppDispatch, user: IUser | null) => {
  if (!user) return;
  const personalVoices = await speechify.getPersonalVoices();
  dispatch(personalVoicesActions.set(personalVoices));
};

export const personalVoicesMiddlewareOnVoiceSelected = async (dispatch: AppDispatch, voice: ExtensionSettings['voice']) => {
  if (voice?.name) {
    const isPersonalVoice = voice.name.startsWith('PVL');

    if (isPersonalVoice) {
      // ESLint: Unexpected any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const props = pick(voice as any, ['displayName', 'name', 'engine', 'gender', 'languageCode']);
      const { displayName, name, engine, gender, languageCode } = props;
      dispatch(personalVoicesActions.select({ displayName, engine, gender, languageCode, name, isPersonalVoice: true }));
    } else {
      dispatch(personalVoicesActions.clearSelection());
    }
  }
};

const personalVoicesMiddleware =
  ({ dispatch }: $TSFixMe) =>
  (next: Dispatch<AnyAction>) =>
  async (action: $TSFixMe) => {
    switch (action.type) {
      case personalVoicesActions.deletePersonalVoice.fulfilled.type: {
        dispatch(personalVoicesActions.setDeletedId(action.payload));
        dispatch(personalVoicesActions.refresh());
        break;
      }
    }

    next(action);
  };

export default personalVoicesMiddleware;
