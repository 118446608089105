import type { IUser } from 'interfaces';
import { isEmpty } from 'lodash';

import { LIFETIME_SUBSCRIPTION_PLAN_NAMES } from 'utils/constants';
import type { Nullable } from 'utils/types';

export const canUpgrade = (user: Nullable<IUser>) => {
  return !hasSubscription(user) || !isPremium(user);
};

export const hasSubscription = (user: Nullable<IUser>) => {
  return !isEmpty(user?.subscription);
};

export const isApple = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user?.subscription?.plan?.source?.name === 'APPLE';
};

export const isCanceled = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user?.subscription?.status?.name === 'CANCELED';
};

export const isExpired = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user?.subscription?.status?.name === 'EXPIRED';
};

export const isOnTrial = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user?.subscription?.isOnTrial === true;
};

export const isLifetime = (currentUser: Nullable<IUser>) =>
  currentUser?.subscription?.plan?.name && LIFETIME_SUBSCRIPTION_PLAN_NAMES.includes(currentUser.subscription.plan.name);

export const isPayPal = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user?.subscription?.plan?.source?.name === 'PAYPAL';
};

export const isPlayStore = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user?.subscription?.plan?.source?.name === 'PLAY_STORE';
};

export const isPremium = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user?.entitlements?.isPremium === true;
};

export const isStripe = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user?.subscription?.plan?.source?.name === 'STRIPE';
};
