import { createSelector, createSlice } from '@reduxjs/toolkit';
import { IUser } from 'interfaces';
import { RootState } from 'store';

import { combinePlatformUsageStats } from 'utils/stats';

const name = 'auth';
type AuthState = {
  isLoading: boolean;
  user: IUser | null;
};
const initialState: AuthState = {
  isLoading: false,
  user: null
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {}
});

export const getCombinedUsageStats = createSelector(
  (state: RootState) => state.auth.user,
  (user: IUser | null) => {
    const { android, iOS, web, ext } = user?.usage?.stats || {};
    return {
      stats: combinePlatformUsageStats(android, iOS, web, ext),
      expires: user?.usage?.expires
    };
  }
);

const selectors = { getCombinedUsageStats };

export { generatedActions, selectors };

export default reducer;
