import { getSDK } from 'modules/sdk/lib';

import { logError } from '../../../lib/observability';
import { useUsageStore } from './usageStore';

export const fetchUserUsageData = async () => {
  try {
    const sdk = await getSDK();
    const userData = await sdk.account.getUserUsageData();
    useUsageStore.setState({
      userData
    });
  } catch (error) {
    logError(error as Error);
    throw error;
  }
};

export const setUserHasReadAtLeastOneItem = (hasRead: boolean) => {
  useUsageStore.setState({ userHasReadAtLeastOneItem: hasRead });
};

export const usageStoreActions = {
  fetchUserUsageData,
  setUserHasReadAtLeastOneItem
};
