import type { Entitlements, Subscription } from '@speechifyinc/multiplatform-sdk';

import { logError } from 'lib/observability';
import { findTtsSubscription, hasStudioSubscription, isTtsSubscription } from 'modules/subscription/utils/subscriptionUtils';
import type { Nullable } from 'utils/types';

import { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';

export type EntitlementsInfo = {
  isPremium: boolean;
  hdWordsLeft: number;
  nextHdWordsGrant: number;
  nextHdWordsGrandDate?: Nullable<Date>;
  lastHdWordsGrantDate?: Nullable<Date>;
};

export class SDKSubscriptionFacade extends SDKFacade {
  private static _singleton: SDKSubscriptionFacade;

  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKSubscriptionFacade._singleton = this;
  }

  static override get singleton(): SDKSubscriptionFacade {
    if (!SDKSubscriptionFacade._singleton) {
      throw new Error('SDKSubscriptionFacade is not initialized');
    }
    return SDKSubscriptionFacade._singleton;
  }

  logHdWordsListened = this.sdk.promisify(this.sdk.client.subscriptionService.logHdWordsListened.bind(this.sdk.client.subscriptionService));

  public mapSDKEntitlementsToEntitlementsInfo = (entitlements: Entitlements | undefined): EntitlementsInfo => {
    if (!entitlements) {
      return {
        isPremium: false,
        hdWordsLeft: 0,
        nextHdWordsGrant: 0,
        nextHdWordsGrandDate: null,
        lastHdWordsGrantDate: null
      };
    }
    return {
      isPremium: entitlements.isPremium,
      hdWordsLeft: entitlements.hdWordsLeft,
      nextHdWordsGrant: entitlements.nextHDWordsGrant,
      nextHdWordsGrandDate: entitlements.nextHDWordsGrantDate ? new Date(entitlements.nextHDWordsGrantDate) : null,
      lastHdWordsGrantDate: entitlements.lastHdWordsGrantDate ? new Date(entitlements.lastHdWordsGrantDate) : null
    };
  };

  public getEntitlements = async (): Promise<EntitlementsInfo> => {
    const sdkGetEntitlementsFn = this.sdk.promisify(this.sdk.client.subscriptionService.getEntitlements.bind(this.sdk.client.subscriptionService));

    const sdkEntitlements = await sdkGetEntitlementsFn();
    return this.mapSDKEntitlementsToEntitlementsInfo(sdkEntitlements);
  };

  public listenToEntitlementsChange = (callback: (entitlements: Entitlements) => void) => {
    return this.sdk.client.subscriptionService.addEntitlementsChangeListener(result => {
      if (result instanceof this.sdk.sdkModule.Result.Success) {
        callback(result.value);
      } else if (result instanceof this.sdk.sdkModule.Result.Failure) {
        logError(new Error(`Failed to listen to entitlements change: ${result.error}`));
        return;
      }
    });
  };

  public listenToSubscriptionChange = (
    callback: (
      change:
        | {
            hasStudioSubscription: boolean;
          }
        | {
            ttsSubscription: Subscription;
          }
    ) => void
  ) => {
    return this.sdk.client.subscriptionService.addSubscriptionChangeListener(result => {
      if (result instanceof this.sdk.sdkModule.Result.Success) {
        if (isTtsSubscription(result.value)) {
          callback({
            ttsSubscription: result.value
          });
        } else if (hasStudioSubscription(result.value)) {
          callback({
            hasStudioSubscription: true
          });
        }
      } else if (result instanceof this.sdk.sdkModule.Result.Failure) {
        logError(new Error(`Failed to listen to subscription change: ${result.error}`));
        return;
      }
    });
  };

  public fetchSubscriptions = async (): Promise<{
    subscription: Subscription | undefined;
    entitlements: Entitlements | undefined;
    hasStudioSubscription: boolean;
  }> => {
    const sdkGetAllSubscriptions = this.sdk.promisify(this.sdk.client.subscriptionService.getAllSubscriptions.bind(this.sdk.client.subscriptionService));
    const sdkSubscriptionAndEntitlements = await sdkGetAllSubscriptions();

    if (!sdkSubscriptionAndEntitlements) {
      return {
        subscription: undefined,
        entitlements: undefined,
        hasStudioSubscription: false
      };
    }
    return {
      subscription: findTtsSubscription(sdkSubscriptionAndEntitlements?.subscriptions),
      entitlements: sdkSubscriptionAndEntitlements?.entitlements,
      hasStudioSubscription: hasStudioSubscription(sdkSubscriptionAndEntitlements?.subscriptions)
    };
  };

  public getBillingDashboardUrl = async (id: string) => {
    const sdkGetBillingDashboardUrl = this.sdk.promisify(this.sdk.client.subscriptionService.getBillingDashboardUrl.bind(this.sdk.client.subscriptionService));
    const parameters = new this.sdk.sdkModule.BillingDashboardOptions(id);
    return sdkGetBillingDashboardUrl(parameters);
  };
}
