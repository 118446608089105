import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { IGamification } from 'interfaces/gamification';
import { logError } from 'lib/observability';
import * as speechify from 'lib/speechify';
import { useAuthStore } from 'modules/auth/store/authStore';
import { authStoreActions } from 'modules/auth/store/authStore/actions';

import { actions } from './index';

let _unsubscribe = () => {};

export const unsubscribe = createAsyncThunk('gamification/unsubscribe', () => {
  _unsubscribe();
  _unsubscribe = () => {};
});

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscribe = createAsyncThunk<any, string, { state: RootState }>(
  'gamification/subscribe',
  async (userId: string, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(actions.enableGamification());

      const subscribedUserId = getState().gamification.subscribedUserId;
      if (subscribedUserId !== userId) {
        dispatch(actions.unsubscribe());
        _unsubscribe = speechify.subscribeToGamification(userId, (gamification: IGamification) => {
          dispatch(actions.set(gamification));
        });
      }

      return;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (!error.response) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        throw error.message;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      rejectWithValue(error.response.data);
    }
  }
);

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setDailyListeningGoal = createAsyncThunk<any, number, { state: RootState }>(
  'gamification/setDailyListeningGoal',
  async (dailyListeningGoalInMinutes: number, { getState, dispatch, rejectWithValue }) => {
    try {
      const userId = useAuthStore.getState().user?.uid;
      const currentGamification = getState().gamification.gamification;
      const updatedGamification = { ...currentGamification, dailyListeningGoalInMinutes };
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      await speechify.setDailyListeningGoal({ dailyListeningGoalInMinutes, userId });
      dispatch(actions.set(updatedGamification));
      authStoreActions.setHasSetDailyListeningGoal(true);
      return;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw rejectWithValue(error.toString());
    }
  }
);

export const setStreakGoal = createAsyncThunk<void, number, { state: RootState }>(
  'gamification/setStreakGoal',
  async (streakGoalInDays: number, { getState, dispatch, rejectWithValue }) => {
    try {
      const userId = useAuthStore.getState().user?.uid;
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      await speechify.setStreakGoal({ streakGoalInDays, userId });
      const currentGamification = getState().gamification.gamification;
      const updatedGamification = { ...currentGamification, streakGoalInDays };
      dispatch(actions.set(updatedGamification));
      return;
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw rejectWithValue(error.toString());
    }
  }
);
