import type { Subscription } from '@speechifyinc/multiplatform-sdk';

export const isStudioSubscription = (subscription: Subscription): boolean => {
  return (
    subscription.plan?.productTypes.includes('voiceover') ||
    subscription.plan?.productTypes.includes('dubbing') ||
    subscription.plan?.productTypes.includes('voicecloning')
  );
};

export const isTtsSubscription = (subscription: Subscription): boolean => {
  return subscription.plan?.productTypes.includes('tts');
};

export const hasStudioSubscription = (subscriptions: Subscription[]): boolean => {
  return subscriptions.some(isStudioSubscription);
};

export const findTtsSubscription = (subscriptions: Subscription[]): Subscription | undefined => {
  return subscriptions.find(isTtsSubscription);
};
