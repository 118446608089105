// src/modules/auth/store/authStore/utils/createAuthStoreAsyncActions.ts
import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';

import { useAuthStore } from '../index';

type AsyncFunction<Args extends unknown[], Return> = (...args: Args) => Promise<Return>;

export function createAuthStoreAsyncAction<Args extends unknown[], Return>(action: AsyncFunction<Args, Return>): AsyncFunction<Args, Return> {
  return async (...args: Args): Promise<Return> => {
    useAuthStore.setState({ isLoading: true });

    try {
      const result = await action(...args);
      return result;
    } catch (error) {
      logError(error as Error, ErrorSource.AUTH);
      throw error;
    } finally {
      useAuthStore.setState({ isLoading: false });
    }
  };
}
