import { createSlice } from '@reduxjs/toolkit';

import { UserUsageData } from 'interfaces/usage';

const name = 'usage';
const initialState: { userData: UserUsageData; userHasReadAtLeastOneItem: boolean } = {
  userData: {
    android: null,
    ios: null,
    webApp: null,
    chromeExtension: null,
    safariExtension: null,
    macApp: null
  },
  userHasReadAtLeastOneItem: false
};

const { reducer } = createSlice({
  name,
  initialState,
  reducers: {}
});

export default reducer;
