import Router from 'next/router';
import nookies from 'nookies';

import { firebaseAuth } from 'lib/firebase/firebase.client';
import { AnalyticsEventKey } from 'modules/analytics/analyticsTypes';
import { extractReason, logAuthAnalyticsEvent } from 'modules/auth/utils/analytic';
import { logOut as extensionLogout } from 'utils/extension';
import { getRequiredStringEnv } from 'utils/safeEnvParsing';

import { currentUserEmitter } from '../../authUserEmitter';
import { resetSessionCookie } from '../utils/cookies';
import { createAuthStoreAsyncAction } from '../utils/createAuthStoreAsyncAction';
import { performAuthStoreCleanupFunctions } from './cleanupAuth';
import { useAuthStore } from '..';

const signOutSameDomainOnboarding = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const onboardingLogoutUrl = `${getRequiredStringEnv('NEXT_PUBLIC_SAME_DOMAIN_ONBOARDING_URL')}/api/onboarding/auth/sign-out`;

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = onboardingLogoutUrl;

    iframe.onload = () => {
      document.body.removeChild(iframe);
      resolve(); // resolve once the iframe is loaded
    };

    iframe.onerror = e => {
      document.body.removeChild(iframe);
      reject(e); // reject if error
    };

    document.body.appendChild(iframe);
  });
};

const onboardingSignOut = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    // TODO(albertusdev): Recheck if this is still relevant with the Foyer migration to site
    const onboardingLogoutUrl = `${getRequiredStringEnv('NEXT_PUBLIC_FOYER_URL')}/api/auth/sign-out`;

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = onboardingLogoutUrl;

    iframe.onload = () => {
      document.body.removeChild(iframe);
      resolve(); // resolve once the iframe is loaded
    };

    iframe.onerror = e => {
      document.body.removeChild(iframe);
      reject(e); // reject if error
    };

    document.body.appendChild(iframe);
  });
};

export const logout = createAuthStoreAsyncAction(async () => {
  try {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    window['uppy'] = null;

    nookies.destroy({}, 'lpobtoken', { domain: '.speechify.com' });
    nookies.set({}, 'authsync', 'out', { path: '/', domain: '.speechify.com' });

    useAuthStore.setState(state => {
      return {
        ...state,
        firebaseUser: null,
        user: null
      };
    }, /* replace */ true);
    await Promise.all([resetSessionCookie(), firebaseAuth.signOut()]);

    await Promise.all([extensionLogout(), onboardingSignOut(), signOutSameDomainOnboarding()]);

    currentUserEmitter.emit('onUserLogout', {});

    performAuthStoreCleanupFunctions();
    Router.push('/login');
  } catch (error) {
    logAuthAnalyticsEvent(AnalyticsEventKey.userAuthenticationFailed, {
      auth_type: 'sign_out',
      reason: extractReason(error)
    });
    throw error;
  }
});
