import type { Entitlements } from '@speechifyinc/multiplatform-sdk';

import { emitUserUpdate } from './emitUserUpdate';
import { useAuthStore } from '..';

export const setEntitlements = (entitlements: Entitlements) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      user: {
        ...state.user,
        entitlements
      }
    };
  });
  emitUserUpdate();
};
