import { AuthProvider as FirebaseAuthProvider, UserCredential, linkWithPopup, signInWithPopup } from 'firebase/auth';

import { ErrorSource } from 'config/constants/errors';
import { firebaseAuth } from 'lib/firebase/firebase.client';
import { logError } from 'lib/observability';
import { AnalyticsEventKey } from 'modules/analytics/analyticsTypes';
import { logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { extractReason, logAuthAnalyticsEvent } from 'modules/auth/utils/analytic';
import { segmentIdentifyByUserId } from 'utils/analytics';

import { setUser } from '../actions/setUser';
import { getSessionCookie } from './cookies';
import { createAuthStoreAsyncAction } from './createAuthStoreAsyncAction';

export enum SignUpActionProvider {
  EMAIL_PASSWORD = 'email',
  GOOGLE = 'google',
  APPLE = 'apple',
  FACEBOOK = 'facebook'
}

export const createSignUpAction = (
  signUpMethod: () => Promise<UserCredential>,
  {
    provider
  }: {
    provider: SignUpActionProvider;
  }
) => {
  return createAuthStoreAsyncAction(async () => {
    let uid: string;
    try {
      const result = await signUpMethod();
      uid = result.user?.uid;
      setUser(result.user);
      await getSessionCookie(await result.user.getIdToken());
      return;
    } catch (error) {
      logAuthAnalyticsEvent(AnalyticsEventKey.userAuthenticationFailed, {
        auth_type: 'sign_up',
        provider: provider,
        reason: extractReason(error)
      });
      logError(error as Error, ErrorSource.AUTH);
    } finally {
      // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
      if (uid) {
        segmentIdentifyByUserId(uid);
        logAnalyticsEvent(AnalyticsEventKey.userAuthenticationLegacy, {
          source: provider,
          mode: 'sign_up'
        });
      }
    }
  })();
};

export const createPopupBasedSignUpAction = (getFirebaseAuthProvider: () => FirebaseAuthProvider, provider: SignUpActionProvider) => {
  return createSignUpAction(
    async () => {
      const authProvider = getFirebaseAuthProvider();
      const currentUser = firebaseAuth.currentUser;

      if (currentUser && (currentUser.isAnonymous || !currentUser.email)) {
        // Convert anonymous user to permanent user
        return linkWithPopup(currentUser, authProvider);
      } else {
        // Sign up new user
        return signInWithPopup(firebaseAuth, authProvider);
      }
    },
    { provider }
  );
};
