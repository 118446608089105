import { IntegrationService } from './integrations';

export type ImportSource = IntegrationService | 'canvas' | 'text' | 'web' | 'scan' | 'aiStory';

export enum ImportDialogUsedFrom {
  PLUS_MODAL = 'plus_modal',
  LIBRARY_EMPTY_STATE = 'library_empty_state',
  CONTEXT_MENU = 'context_menu'
}

export interface TextImport {
  text: string;
  title: string;
}

export interface UrlImport {
  url: string;
}

export enum ALLOWED_MIME_TYPES {
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  GDOC = 'application/vnd.google-apps.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XML = 'application/xhtml+xml',
  EPUB = 'application/epub+zip',
  PDF = 'application/pdf',
  RTF = 'application/rtf',
  TXT = 'text/plain',
  HTML = 'text/html',
  ALL_TEXT = 'text/*'
}
