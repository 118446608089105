import createIdbStorage from '@piotr-cz/redux-persist-idb-storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector, useStore as useReduxStore } from 'react-redux';
import { persistStore } from 'redux-persist';

import { getOptionalStringEnv } from '../utils/safeEnvParsing';
import appReducer from './app';
import authReducer from './auth';
import { REDUX_IDB_DB_NAME, REDUX_IDB_OBJECT_STORE_NAME } from './constants';
import gamificationReducer from './gamification';
import integrationReducer from './integration';
import itemReducer from './item';
import { LegacyReduxStoreDarkModeState } from './legacyReduxTypes';
import libraryReducer from './library';
import libraryMiddleware from './library/middleware';
import onboardingReducer from './onboarding';
import personalVoicesReducer from './personalVoices';
import personalVoicesMiddleware from './personalVoices/middleware';
import teamReducer from './team';
import usageReducer from './usage';

const reducers = {
  app: appReducer,
  auth: authReducer,
  gamification: gamificationReducer,
  /* @deprecated redux integration store is deprecated, please use the `useIntegrationStore` zustand store instead */
  integration: integrationReducer,
  item: itemReducer,
  library: libraryReducer,
  onboarding: onboardingReducer,
  personalVoices: personalVoicesReducer,
  team: teamReducer,
  /**
   * @deprecated redux usage store is deprecated, please use the `useUsageStore` zustand store instead
   * This will be removed once Library Overhaul project is complete
   */
  usage: usageReducer
} as const;

const rootReducer = combineReducers(reducers);

const actionSanitizer = (action: $TSFixMe) =>
  action.type === 'library/downloadPage/fulfilled' && action.payload ? { ...action, payload: { ...action.payload, html: '...', textContent: '...' } } : action;

const stateSanitizer = (state: $TSFixMe): $TSFixMe => {
  if (state.library && state.library.pages) return { ...state, library: { ...state.library, pages: {} } };
  return state;
};

const isClient = typeof window !== 'undefined';

let storeState: typeof rootReducer;

const PERSISTED_STATE_KEYS = ['app', 'auth', 'gamification', 'library', 'usage', 'onboarding', 'personalVoices', 'integration'] as const;

if (isClient && getOptionalStringEnv('NEXT_PUBLIC_IS_STORYBOOK') !== 'true') {
  // ESLint: Require statement not part of import statement
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { persistReducer } = require('redux-persist');
  // ESLint: Require statement not part of import statement
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const storage = require('redux-persist/lib/storage').default;

  const persistConfig = {
    key: 'root',
    storage: globalThis.indexedDB ? createIdbStorage({ name: REDUX_IDB_DB_NAME, storeName: REDUX_IDB_OBJECT_STORE_NAME }) : storage,
    whitelist: PERSISTED_STATE_KEYS
  };

  storeState = persistReducer(persistConfig, rootReducer);
} else {
  storeState = rootReducer;
}

const store = configureStore({
  reducer: storeState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(libraryMiddleware)
      .concat(personalVoicesMiddleware),
  devTools: getOptionalStringEnv('NODE_ENV') !== 'production' ? { actionSanitizer, stateSanitizer } : false
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useStore: () => typeof store = useReduxStore;

export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export interface RootState extends ReturnType<typeof rootReducer> {}

type PersistedStateKey = (typeof PERSISTED_STATE_KEYS)[number];

export type SpeechifyPersistedStoreState = Pick<RootState, PersistedStateKey> & {
  darkMode: LegacyReduxStoreDarkModeState;
};

export { persistor, store };
