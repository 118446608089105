import type { AccountSettings } from '@speechifyinc/multiplatform-sdk';

import { logError } from 'lib/observability';
import { getSDK } from 'modules/sdk/lib';

let settings: AccountSettings | null = null;

export const DAILY_QUESTION_LIMIT_KEY = 'dailyQuestionLimit';
export const LAST_BASIC_TRIAL_REFRESH = 'lastBasicTrialRefresh';
export const TRIAL_EXTENDED = 'trialExtended';
export const FIRST_PDF_DOCUMENT = 'firstPdfDocument';

export const refreshAccountSettings = async () => {
  const sdk = await getSDK();
  settings = await sdk.account.getAccountSettings();
};

export const getAccountSettings = async () => {
  if (!settings) {
    await refreshAccountSettings();
  }

  try {
    return settings?.copy() ?? null;
  } catch (e) {
    logError(e as Error);
    return Promise.reject('Unable to retrieve account settings: ' + e);
  }
};

export const setAccountSettings = async (accountSettings: AccountSettings) => {
  const sdk = await getSDK();
  settings = await sdk.account.setAccountSettings(accountSettings.copy());
  return settings.copy();
};

export const getCustomAccountSetting = async (key: string): Promise<string | null> => {
  return (await getAccountSettings())?.customSettings.get(key) ?? null;
};

export const setCustomAccountSetting = async (key: string, value: string) => {
  const settings = await getAccountSettings();
  if (!settings) {
    return;
  }
  settings.customSettings.set(key, value);
  return setAccountSettings(settings);
};
