import { createSlice } from '@reduxjs/toolkit';

import { IntegrationState } from './types';

const name = 'integration';
const initialState: IntegrationState = {
  auths: {},
  isLoading: false,
  importedItems: {},
  suggestedItems: [],
  showTooltip: {}
};

const { reducer } = createSlice({
  name,
  initialState,
  reducers: {}
});

export default reducer;
