import { isSafari } from '@firebase/util';

import { ErrorSource } from 'config/constants/errors';
import { Response } from 'lib/audio/proto/get';
import { logError } from 'lib/observability';
import { auth } from 'lib/speechify';

import { isMobile } from './browser';
import { getRequiredStringEnv, getStringEnvWithDefault } from './safeEnvParsing';

export const arrayBufferToBase64 = (buffer: ArrayBuffer, format: 'mp3' | 'ogg') =>
  new Promise<string>(resolve => {
    const blob = new Blob([buffer], { type: `audio/${format}` });

    const reader = new FileReader();
    reader.addEventListener('load', ev => resolve(ev.target?.result as string));
    reader.readAsDataURL(blob);
  });

export const getAudio = async (ssml = '', voiceParams: $TSFixMe) => {
  try {
    const url = `${getRequiredStringEnv('NEXT_PUBLIC_AUDIO_SERVER_URL')}/v2/synthesis/get`;

    const { name, engine, languageCode: language } = voiceParams;
    const requestBody = {
      ssml,
      voice: {
        name,
        engine,
        language
      },
      forcedAudioFormat: !isSafari() ? 'ogg' : 'mp3'
    };

    const user = auth.currentUser;
    if (!user || user.isAnonymous) return;

    const idToken = await user.getIdToken();
    const activeToken = await getSignedToken(idToken, getRequiredStringEnv('NEXT_PUBLIC_AUDIO_SERVER_URL'));

    const headers = {
      Authorization: `Bearer ${activeToken}`,
      'X-Speechify-Client': 'WebApp',
      'X-Speechify-Client-Version': getStringEnvWithDefault('version', '0.0.0'),
      'Content-type': 'application/json',
      // disables sentence splitting
      'X-Speechify-Synthesis-Options': 'sentence-splitting=true'
    };

    const audio = await fetch(url, {
      headers,
      method: 'POST',
      body: JSON.stringify(requestBody)
    })
      .then(res => res.arrayBuffer())
      .then(data => Response.fromBinary(new Uint8Array(data)))
      .catch(err => {
        throw err;
      });

    return audio;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.PLAYBACK);
    return {};
  }
};

const getSignedToken = (idToken: string, audioServerUrl: string) => {
  return fetch(`${audioServerUrl}/v1/auth/sign?id_token=${idToken}`)
    .then(res => res.json())
    .then(({ token }) => token);
};

export const shouldAutoplay = (): boolean => !isMobile();

export const checkAutoplayIsAvailable = (): Promise<boolean> => {
  return new Promise(resolve => {
    const audio = new Audio();

    audio.onplay = () => {
      resolve(true);
      audio.pause();
    };

    audio.onerror = () => {
      resolve(false);
    };

    try {
      audio.play().catch(() => resolve(false));
    } catch (err) {
      resolve(false);
    }
  });
};
