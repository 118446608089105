import { firebaseAuth } from 'lib/firebase/firebase.client';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { getRequiredStringEnv } from 'utils/safeEnvParsing';

export function getAuthProvider() {
  const provider = firebaseAuth.currentUser?.providerData?.find(provider => provider.providerId !== 'password')?.providerId;
  switch (provider) {
    case 'google.com':
      return 'google';
    case 'facebook.com':
      return 'facebook';
    case 'apple.com':
      return 'apple';
    case 'github.com':
      return 'github';
    default:
      return 'email_password';
  }
}

export function logAuthAnalyticsEvent(
  event: AnalyticsEventKey.userSignedIn | AnalyticsEventKey.userSignedOut | AnalyticsEventKey.userAuthenticationFailed,
  payload?: Record<string, unknown>
) {
  logAnalyticsEvent(event, {
    flow: 'web_app_main_page',
    source_url: location.href,
    provider: getAuthProvider(),
    app_platform: 'web_app',
    app_version: getRequiredStringEnv('version'),
    ...payload
  });
}

export function extractReason(error: unknown) {
  let reason = 'unknown reason';
  try {
    reason = error instanceof Error ? error.message : String(error || reason);
  } catch {
    // eslint-disable-next-line no-empty
  }
  return reason;
}
