import type { VoiceSelectionTabs } from 'components/newListeningExperience/player/Screens/VoiceSelector';
import { FileActions } from 'components/newListeningExperience/topnav/FileAction';

import type { BooleanSettingKey, SkipContentAnalyticsTypes } from 'modules/listening/features/settings/settings';
import { ListeningScreenTabs } from 'modules/listening/navStore';
import { ContentMetaType, ContentMetaTypeLowercase } from 'modules/sdk/lib/facade/listenableContent/content.types';

import { AudioDownloadAnalyticsEventProperties } from './event-types/audioDownload';
import type { ListenToDocumentTriggeredFrom } from './impl/playbackAnalytics';

export enum AnalyticsEventKey {
  aiStoryImportStarted = 'ai_story_import_started',
  aiStoryGenreSelected = 'ai_story_genre_selected',
  aiStoryPreviewSelected = 'ai_story_preview_selected',
  aiStoryGeneratePreviewsClicked = 'ai_story_generate_previews_clicked',
  aiStoryGenerateCoversClicked = 'ai_story_generate_covers_clicked',
  aiStoryGenerateCoversError = 'ai_story_generate_covers_error',
  aiStoryGenerateStoryClicked = 'ai_story_generate_story_clicked',
  aiStoryListenClicked = 'ai_story_listen_clicked',
  aiStoryPreviewListened = 'ai_story_preview_listened',
  askAISummaryFeedbackSubmitted = 'web_app_document_summary_feedback_submitted',
  askAISummaryRatingSubmitted = 'web_app_document_summary_rating_submitted',
  changedVoiceTab = 'web_app_changed_voice_tab',
  documentAskAi = 'web_app_document_ask_ai',
  documentAskAiAutoplayResponses = 'web_app_document_ask_ai_autoplay_responses',
  documentAskAiChatCleared = 'web_app_document_ask_ai_chat_cleared',
  documentAskAiClearChatModalOpened = 'web_app_document_ask_ai_clear_chat_modal_opened',
  documentAskAiCloseChat = 'web_app_document_ask_ai_close_chat',
  documentAskAiListenedToAnswer = 'web_app_document_ask_ai_listened_to_answer',
  documentAskAiListenedToQuestion = 'web_app_document_ask_ai_listened_to_quiz_question',
  documentAskAiCopyAnswer = 'web_app_document_ask_ai_copy_answer',
  documentCompleted = 'web_app_document_completed',
  documentDeleted = 'web_app_document_deleted',
  documentOpened = 'web_app_document_opened',
  documentOpenedDefault = 'web_app_default_document_opened',
  documentOpenedRelisten = 'web_app_document_opened_relisten',
  documentSummaryClicked = 'web_app_document_summary_clicked',
  documentActionsMenuClicked = 'web_app_document_actions_menu_clicked',
  documentActionsMenuItemClicked = 'web_app_document_actions_menu_item_clicked',
  helpCenterFeatureRequest = 'web_help_center_feature_request',
  helpCenterMessageUpgrade = 'web_help_center_message_upgrade',
  helpCenterReportBrokenExperience = 'web_help_center_report_broken_experience',
  helpUsImprove = 'web_help_us_improve',
  homeButtonPressed = 'web_app_home_button_pressed',
  instantListeningUploadWarningModalConfirmButtonClicked = 'web_app_instant_listening_upload_warning_modal_confirm_button_clicked',
  instantListeningUploadWarningModalDismissButtonClicked = 'web_app_instant_listening_upload_warning_modal_dismiss_button_clicked',
  instantListeningUploadWarningModalShown = 'web_app_instant_listening_upload_warning_modal_shown',
  listeningFeedbackContent = 'listening_feedback_content',
  listeningFeedbackRating = 'listening_feedback_rating',
  listeningSettingsClicked = 'web_app_listening_settings_clicked',
  listeningSettingsSkipContentClicked = 'clicked_skip_content',
  listeningSettingsZoomChanged = 'web_app_listening_settings_zoom_changed',

  mp3DownloadModalOpened = 'mp3_download_modal_opened',
  mp3DownloadVoiceSelected = 'mp3_download_voice_selected',
  mp3DownloadScopeSelected = 'mp3_download_scope_selected',
  mp3DownloadStarted = 'mp3_download_started',
  mp3DownloadUpgradeTrial = 'mp3_download_upgrade_trial',
  mp3DownloadLimitReached = 'mp3_download_limit_reached',
  mp3DownloadCompleted = 'mp3_download_completed',
  mp3DownloadModalClosed = 'mp3_download_modal_closed',
  mp3DownloadError = 'mp3_download_error',

  accountPage = 'web_app_account_page_upgrade',
  askAI = 'web_app_ask_ai_upgrade',
  checkoutModalOpened = 'checkout_modal_opened',
  downloadMp3 = 'web_app_download_mp3_upgrade',
  downloadMp3v2 = 'web_app_download_mp3_v2_upgrade',
  imageOnlyPDFs = 'web_app_image_only_pdfs_upgrade',
  openedDocumentSettings = 'opened_document_settings',
  pdfLimitPopup = 'web_app_pdf_limit_popup',
  playerJumpBySentence = 'web_app_jump_by_sentence',
  playerListenToDocument = 'web_app_listen_to_document',
  playerSettings = 'web_app_player_settings',
  playerSpeedChanged = 'web_app_speed_changed',
  playerSpeedRampChanged = 'web_app_speed_ramp_changed',
  playerVoiceChanged = 'web_app_voice_changed',
  premiumVoices = 'web_app_premium_voices_upgrade',
  profoile = 'web_app_profile_upgrade',
  sidebar = 'web_app_sidebar_upgrade',
  skipHeaderFooterUpsell = 'web_app_skip_header_footer_upsell',
  speedControls = 'web_app_speed_controls_upgrade',
  speedPickerUpsell = 'web_app_speed_picker_upsell',
  subscriptionSettings = 'web_app_subscription_settings_upgrade',
  switchedToFree = 'web_app_switched_to_free',
  topNavItemClicked = 'web_app_top_nav_item_clicked',
  unlimitedFiles = 'web_app_unlimited_files_upgrade',
  unlimitedSpeedPopup = 'web_app_unlimited_speed_popup',
  upgradePressedAudioVisualCta = 'web_app_audio_visual_cta_upgrade_pressed',
  upgradePressedContextual = 'web_app_contextual_upsell_upgrade_clicked',
  upgradePressedContextualControl = 'web_app_contextual_upsell_control_upgrade_clicked',
  upgradePressedDashboard = 'web_app_dashboard_upgrade_clicked',
  upgradePressedOcrUpsell = 'web_app_ocr_upsell_upgrade_clicked',
  upsellModalsAudioVisualCtaClosed = 'web_app_audio_visual_cta_closed',
  upsellModalsContextual = 'web_app_contextual_upsell_shown',
  upsellModalsOcrUpsellShown = 'web_app_ocr_upsell_shown',
  usageWordsListened = 'web_app_usage_words_listened',
  usageWordsListenedGamification = 'web_app_usage_words_listened_gamification',
  userAuthenticationLegacy = 'web_app_user_authentication',
  signOutPressed = 'web_app_sign_out_pressed',
  settingsPressed = 'web_app_settings_opened',
  privacyPressed = 'web_app_privacy_opened',
  termsPressed = 'web_app_terms_opened',
  userAuthenticationFailed = 'User Authentication Failed',
  userSignedIn = 'User Signed In',
  userSignedOut = 'User Signed Out',
  voiceControls = 'web_app_voice_controls_upgrade',
  voiceSelector = 'web_app_voice_selector_upgrade'
}

export type Properties<Context extends Record<string, unknown>> = {} & Context;

type AuthEventProperties = {
  provider: string;
  flow: string;
  source_url: string;
  app_platform: string;
  app_version: string;
};

export type AnalyticsEventProperties = {
  // start: AI Story events
  [AnalyticsEventKey.aiStoryImportStarted]: Properties<{}>;

  [AnalyticsEventKey.aiStoryGenreSelected]: Properties<{
    genre: string;
  }>;

  [AnalyticsEventKey.aiStoryPreviewSelected]: Properties<{
    books: string;
    genre: string;
    preview: string;
  }>;

  [AnalyticsEventKey.aiStoryGeneratePreviewsClicked]: Properties<{
    books: string;
    genre: string;
  }>;

  [AnalyticsEventKey.aiStoryGenerateCoversClicked]: Properties<{
    books: string;
    genre: string;
  }>;

  [AnalyticsEventKey.aiStoryGenerateCoversError]: Properties<{}>;

  [AnalyticsEventKey.aiStoryGenerateStoryClicked]: Properties<{
    books: string;
    genre: string;
    title: string;
    preview: string;
  }>;

  [AnalyticsEventKey.aiStoryListenClicked]: Properties<{
    books: string;
    genre: string;
    title: string;
  }>;
  // end: AI Story events
  [AnalyticsEventKey.documentAskAi]: Properties<
    | {
        action: 'chat';
        query: string;
      }
    | {
        action: 'summary_regenerate';
      }
    | {
        action: 'generate_summary';
        allPages: boolean;
        length: 'short' | 'medium' | 'long';
        mode: 'paragraph' | 'keypoints';
        pageIndexes: number[];
      }
    | {
        action: 'generate_quiz';
        allPages: boolean;
        pageIndexes: number[];
        questionCount: number;
        fileType: ContentMetaType;
      }
    | { action: 'answer_quiz'; quizId: string; questionKey: string; answerIndex: number; fileType: ContentMetaType; method: 'keyboard' | 'mouse' }
    | { action: 'retake_quiz'; quizId: string; fileType: ContentMetaType }
    | {
        action: 'complete_quiz';
        quizId: string;
        fileType: ContentMetaType;
        questionCount: number;
        correctAnswerCount: number;
        percentCorrect: number;
      }
  >;
  [AnalyticsEventKey.documentAskAiAutoplayResponses]: Properties<{
    state: 'on' | 'off';
    source: 'chat' | 'quiz';
  }>;
  [AnalyticsEventKey.documentAskAiListenedToAnswer]: Properties<{
    via: 'autoplay' | 'button';
  }>;
  [AnalyticsEventKey.documentAskAiListenedToQuestion]: Properties<{
    fileType: ContentMetaType;
    via: 'autoplay' | 'button';
  }>;
  [AnalyticsEventKey.changedVoiceTab]: Properties<{
    changedTabTo: VoiceSelectionTabs;
  }>;
  [AnalyticsEventKey.listeningSettingsSkipContentClicked]: Properties<{
    type: 'all' | SkipContentAnalyticsTypes;
    value: 'on' | 'off';
  }>;
  [AnalyticsEventKey.documentDeleted]: Properties<{
    type: ContentMetaTypeLowercase;
  }>;
  [AnalyticsEventKey.documentOpened]: Properties<{
    type: ContentMetaTypeLowercase;
  }>;
  [AnalyticsEventKey.documentOpenedRelisten]: Properties<{
    type: ContentMetaTypeLowercase;
  }>;
  [AnalyticsEventKey.documentSummaryClicked]: Properties<{
    state: 'collapse' | 'expanded';
    fileType: ContentMetaType;
    fileWordCount?: number;
  }>;
  [AnalyticsEventKey.documentActionsMenuClicked]: Properties<{
    from: 'listening_screen' | 'library_screen';
  }>;
  [AnalyticsEventKey.documentActionsMenuItemClicked]: Properties<{
    from: 'listening_screen' | 'library_screen';
    action: FileActions;
    documentType: ContentMetaType;
  }>;

  [AnalyticsEventKey.helpCenterFeatureRequest]: Properties<{ featureRequest: string }>;

  [AnalyticsEventKey.helpCenterReportBrokenExperience]: Properties<{ feedback: string }>;

  [AnalyticsEventKey.homeButtonPressed]: Properties<{
    source: string;
  }>;
  [AnalyticsEventKey.listeningSettingsClicked]: Properties<{
    key: BooleanSettingKey;
    value: boolean;
  }>;
  [AnalyticsEventKey.listeningSettingsZoomChanged]: Properties<{
    zoomPercentage: number;
  }>;
  [AnalyticsEventKey.listeningFeedbackRating]: Properties<{
    sentiment: number;
    // In old FeedbackCsat.tsx, there is already a property called isNewListeningExperience
    // so I am giving this event a new property to distinguish
    isRehaul: boolean;
  }>;
  [AnalyticsEventKey.openedDocumentSettings]: Properties<{
    state: 'locked' | 'unlocked';
  }>;
  [AnalyticsEventKey.playerListenToDocument]: Properties<{
    triggeredFrom: ListenToDocumentTriggeredFrom;
    voiceId: string;
    voiceName: string;
    isHD: boolean;
    engine?: string;
    isClonedVoice?: boolean;
  }>;
  [AnalyticsEventKey.playerJumpBySentence]: Properties<{
    backward: boolean;
  }>;
  [AnalyticsEventKey.playerSpeedChanged]: Properties<{
    speed: number;
    wpm: number;
    source?: 'speedUI' | 'keyboardShortcut' | 'autoIncrease' | 'switchToFree' | 'switchToNonPremium';
  }>;
  [AnalyticsEventKey.playerSpeedRampChanged]: Properties<{
    value: boolean;
  }>;
  [AnalyticsEventKey.playerVoiceChanged]: Properties<{
    voiceId: string;
    voiceName: string;
    isHD: boolean;
    engine?: string;
  }>;
  [AnalyticsEventKey.topNavItemClicked]: Properties<{
    tab: ListeningScreenTabs | null;
  }>;
  [AnalyticsEventKey.usageWordsListened]: Properties<{
    is_highlighting_enabled: boolean;
    isEmbedded: boolean;
    isHD: boolean;
    voice_id: string;
    speed: number;
    isClonedVoice: boolean;
  }>;
  [AnalyticsEventKey.usageWordsListenedGamification]: Properties<{
    is_highlighting_enabled: boolean;
    isHD: boolean;
    voice_id: string;
    threshold: number;
    wpm: number;
    isGamificationEnabled: boolean;
    isClonedVoice: boolean;
  }>;
  [AnalyticsEventKey.upgradePressedDashboard]: Properties<{
    source: string;
    vcw?: boolean;
  }>;
  [AnalyticsEventKey.upsellModalsContextual]: Properties<{
    variant: string;
  }>;
  [AnalyticsEventKey.askAISummaryFeedbackSubmitted]: Properties<{
    feedback: string;
    itemId: string;
  }>;
  [AnalyticsEventKey.askAISummaryRatingSubmitted]: Properties<{
    rating: 'like' | 'dislike';
  }>;
  [AnalyticsEventKey.userSignedOut]: Properties<AuthEventProperties>;
  [AnalyticsEventKey.userSignedIn]: Properties<AuthEventProperties>;
  [AnalyticsEventKey.userAuthenticationFailed]: Properties<AuthEventProperties>;
  [AnalyticsEventKey.checkoutModalOpened]: Properties<{
    source: string;
  }>;
  [AnalyticsEventKey.userAuthenticationLegacy]: Properties<{
    source: 'email' | 'customToken' | 'google' | 'apple' | 'facebook' | 'Anonymous';
    mode: 'login' | 'sign_up';
  }>;
  [AnalyticsEventKey.helpUsImprove]: Properties<{
    feedback: string;
  }>;
} & AudioDownloadAnalyticsEventProperties;

export type AnalyticsEventProperty<Key extends AnalyticsEventKey> = Key extends keyof AnalyticsEventProperties ? AnalyticsEventProperties[Key] : {};
