import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import { authStoreActions } from 'modules/auth/store/authStore/actions';
import { type VoiceInfo } from 'modules/sdk/lib';
import { SDKVoiceFacade } from 'modules/sdk/lib/facade';

const setPlaybackSpeed = (currentSpeed: number) => {
  try {
    authStoreActions.setExtensionPlaybackSpeed(currentSpeed);
  } catch (error) {
    logError(error as Error, ErrorSource.EXTENSION_COMPATIBILITY, { context: { function: 'setPlaybackSpeed', speed: currentSpeed + '' } });
  }
};

const setVoice = (voice: VoiceInfo) => {
  const voiceSpec = SDKVoiceFacade.singleton.getVoiceSpec(voice);
  const voiceMeta = voiceSpec.toVoiceMetadata();
  const { engine, gender, languageCode } = voiceMeta;
  const { displayName, name } = voice;

  try {
    if (name) {
      authStoreActions.setExtensionVoice({ displayName, engine, gender: gender.name.toLowerCase(), languageCode, name });
    }
  } catch (error) {
    logError(error as Error, ErrorSource.EXTENSION_COMPATIBILITY, { context: { function: 'setVoice', voice: voice.id } });
  }
};

export default {
  setPlaybackSpeed,
  setVoice
};
