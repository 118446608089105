import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { createNonPersistentStore } from 'lib/zustand/store';
import { logAnalyticsEventOnBackwardClick, logAnalyticsEventOnForwardClick, logAnalyticsEventOnPlay } from 'modules/analytics/impl/playbackAnalytics';
import { askAiStoreActions } from 'modules/ask-ai/store';
import type { Virtualizer } from 'modules/listening/features/reader/components/classic/ClassicReaderV2';
import { getMaxZoomPercentage, getMinZoomPercentage, onZoomChange } from 'modules/listening/features/settings/implementation/zoom';
import { listeningSettingsStoreActions } from 'modules/listening/features/settings/settingsStore';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { ListenableContent, PlaybackInfo, SDKContentBundlerOptionsFacade } from 'modules/sdk/lib';
import { PDFListenableContent } from 'modules/sdk/lib/facade/listenableContent/types';
import { listenableContentErrorContext } from 'modules/sdk/lib/facade/listenableContent/utils';
import { ListeningDependencies, ListeningDependenciesFactory } from 'modules/sdk/listeningDependencies';
import { tryQueueMobileUpsellBanner } from 'modules/sideBanner/stores/continuePhoneActions';
import { speedStoreActions } from 'modules/speed/store/speedStore';
import { subscriptionStoreActions } from 'modules/subscription/stores/actions';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { voiceStoreActions } from 'modules/voices/stores/actions';
import { checkAutoplayIsAvailable, shouldAutoplay } from 'utils/audio';

type BasePlaybackState = {
  latestPlaybackState: undefined;
  currentListenableContent: undefined;
  // @deprecated use listeningDependencies instead
  currentPlaybackInfo: undefined;
  // @deprecated use listeningDependencies instead
  currentOverlayInfo: undefined;
  // @deprecated use listeningDependencies instead
  contentBundlerOptionsFacade: undefined;
  listeningDependencies: undefined;
  currentPspdfKitFacade: undefined;
};

type InitialState = BasePlaybackState & {
  isReady: false;
};

type LoadingState = BasePlaybackState & {
  isReady: false;
};

export type PlaybackStoreReadyState = {
  isReady: true;

  currentListenableContent: ListenableContent;
  listeningDependencies: ListeningDependencies;
  // @deprecated use listeningDependencies instead
  currentPlaybackInfo: ListeningDependencies['playbackInfo'];
  // @deprecated use listeningDependencies instead
  currentOverlayInfo: ListeningDependencies['overlayInfo'];
  // @deprecated use listeningDependencies instead
  contentBundlerOptionsFacade: SDKContentBundlerOptionsFacade;
  latestPlaybackState: PlaybackInfo['latestState'];
  currentPspdfKitFacade: PSPDFKitFacade;
};

export type PlaybackStoreState = InitialState | LoadingState | PlaybackStoreReadyState;

export const usePlaybackStore = createNonPersistentStore<PlaybackStoreState>(
  () => {
    return {
      isReady: false,
      currentPlaybackInfo: undefined,
      currentOverlayInfo: undefined,
      latestPlaybackState: undefined,
      currentListenableContent: undefined,
      contentBundlerOptionsFacade: undefined,
      listeningDependencies: undefined,
      currentPspdfKitFacade: undefined
    };
  },
  {
    isListeningScreenStore: true
  }
);

usePlaybackStore.subscribe((state, prevState) => {
  if (state.currentPlaybackInfo !== prevState.currentPlaybackInfo) {
    if (prevState.currentPlaybackInfo) {
      prevState.currentPlaybackInfo.controls.pause();
    }

    if (prevState.listeningDependencies) {
      prevState.listeningDependencies.destroy();
    }

    if (state.currentPlaybackInfo) {
      // note: it's safe to not clean this up because the listener will be removed when the PlaybackInfo is destroyed, see PlaybackInfo.destroy() implementation
      state.currentPlaybackInfo.addStateListener(playbackState => {
        usePlaybackStore.setState(() => ({
          latestPlaybackState: playbackState
        }));
      });
    }
  }
});

const _maybeStartAutoPlay = async (listeningDependencies: ListeningDependencies) => {
  const autoplay = shouldAutoplay();
  if (autoplay) {
    const isPlaying = listeningDependencies.playbackInfo.latestState.isPlaying;
    if (!isPlaying) {
      const isAutoplayAvailable = await checkAutoplayIsAvailable();
      if (isAutoplayAvailable) {
        resume();
        logAnalyticsEventOnPlay('auto play');
      }
    }
  }
};

const readerBasedInit = async (listenableContent: ListenableContent, listeningDependencies: ListeningDependencies) => {
  usePlaybackStore.setState(() => ({
    currentListenableContent: listenableContent,
    listeningDependencies
  }));

  await voiceStoreActions.initializeVoiceStore(listeningDependencies);
  await listeningSettingsStoreActions.initializeListeningSettingsStore({ listeningDependencies, isReaderBased: true });
  subscriptionStoreActions.startWordTracker(listeningDependencies);

  usePlaybackStore.setState(() => ({
    isReady: true
  }));

  // mobile app upsell banner
  tryQueueMobileUpsellBanner();
};

const init = async (listenableContent: ListenableContent, listeningDependencies: ListeningDependencies) => {
  usePlaybackStore.setState(() => ({
    currentListenableContent: listenableContent,
    listeningDependencies,
    currentPlaybackInfo: listeningDependencies.playbackInfo,
    currentOverlayInfo: listeningDependencies.overlayInfo,
    latestPlaybackState: listeningDependencies.playbackInfo.latestState,
    contentBundlerOptionsFacade: listeningDependencies.contentBundlerOptionsFacade
  }));

  await speedStoreActions.initializeSpeedStore();
  await voiceStoreActions.initializeVoiceStore(listeningDependencies);
  await listeningSettingsStoreActions.initializeListeningSettingsStore({ listeningDependencies, isReaderBased: false });
  await askAiStoreActions.initializeAskAiStore();

  subscriptionStoreActions.startWordTracker(listeningDependencies);

  usePlaybackStore.setState(() => ({
    isReady: true
  }));

  // run on the next tick once the UI is updated
  setTimeout(() => {
    _maybeStartAutoPlay(listeningDependencies);
  }, 1);
  // mobile app upsell banner
  tryQueueMobileUpsellBanner();
};

const initializeReaderBasedBookExperience = async (listenableContent: ListenableContent): Promise<ListeningDependencies> => {
  const state = usePlaybackStore.getState();
  const currentListenableContent = state.currentListenableContent;
  if (currentListenableContent === listenableContent) {
    return state.listeningDependencies;
  }
  try {
    usePlaybackStore.setState(() => ({
      isReady: false
    }));

    profilingStoreActions.startMeasurement(MeasurementKey.sdkSetup);

    PSPDFKitFacade.makeTheNextInstanceHeadless();
    const listeningDependencies = await ListeningDependenciesFactory.singleton.create({
      listenableContent
    });

    await readerBasedInit(listenableContent, listeningDependencies);
    profilingStoreActions.endMeasurement(MeasurementKey.instantListeningBookReaderReady);
    profilingStoreActions.measureFromNavigationStart(MeasurementKey.bookReaderReady, {});

    return listeningDependencies;
  } catch (errorMessage) {
    const error = errorMessage instanceof Error ? errorMessage : new Error(`${errorMessage}`);
    logError(error, ErrorSource.PLAYBACK, {
      context: listenableContentErrorContext(listenableContent)
    });

    usePlaybackStore.setState(() => ({
      isReady: false
    }));

    throw error;
  }
};

const initializePdfExperience = async (listenableContent: PDFListenableContent, pdfContainer: HTMLElement): Promise<PSPDFKitFacade> => {
  const state = usePlaybackStore.getState();
  const currentListenableContent = state.currentListenableContent;
  if (currentListenableContent === listenableContent) {
    return state.currentPspdfKitFacade;
  }
  try {
    usePlaybackStore.setState(() => ({
      isReady: false
    }));
    profilingStoreActions.startMeasurement(MeasurementKey.sdkSetup);

    PSPDFKitFacade.setPdfContainerForNextInstance(pdfContainer);
    const listeningDependencies = await ListeningDependenciesFactory.singleton.create({
      listenableContent
    });
    const currentPspdfKitFacade = await PSPDFKitFacade.getInstanceForCurrentContainer();

    await init(listenableContent, listeningDependencies);
    profilingStoreActions.endMeasurement(MeasurementKey.instantListeningBookReaderReady);
    profilingStoreActions.measureFromNavigationStart(MeasurementKey.bookReaderReady, {});

    // update settings
    onZoomChange(currentPspdfKitFacade.instance.currentZoomLevel * 100, {
      maxZoom: Math.min(getMaxZoomPercentage(), Math.floor(currentPspdfKitFacade.instance.maximumZoomLevel * 100)),
      minZoom: Math.max(getMinZoomPercentage(), Math.ceil(currentPspdfKitFacade.instance.minimumZoomLevel * 100))
    });
    return currentPspdfKitFacade;
  } catch (errorMessage) {
    const error = errorMessage instanceof Error ? errorMessage : new Error(`${errorMessage}`);
    logError(error, ErrorSource.PLAYBACK, {
      context: listenableContentErrorContext(listenableContent)
    });

    usePlaybackStore.setState(() => ({
      isReady: false
    }));

    throw error;
  }
};

const initializeClassicExperience = async ({
  listenableContent,
  scrollerElement,
  overlayElement,
  virtualizer
}: {
  listenableContent: ListenableContent;
  scrollerElement: HTMLElement;
  overlayElement: HTMLElement;
  virtualizer: Virtualizer | undefined;
}): Promise<ListeningDependencies> => {
  const currentState = usePlaybackStore.getState();
  if (currentState.currentListenableContent === listenableContent) {
    return currentState.listeningDependencies;
  }
  try {
    const listeningDependencies = await ListeningDependenciesFactory.singleton.create({
      listenableContent,
      scrollerElement: scrollerElement,
      overlayElement: overlayElement,
      virtualizer
    });

    await init(listenableContent, listeningDependencies);
    profilingStoreActions.endMeasurement(MeasurementKey.instantListeningClassicReaderReady);
    profilingStoreActions.measureFromNavigationStart(MeasurementKey.classicReaderReady, {});
    return listeningDependencies;
  } catch (errorMessage) {
    const error = errorMessage instanceof Error ? errorMessage : new Error(`${errorMessage}`);
    logError(error, ErrorSource.PLAYBACK, {
      context: listenableContentErrorContext(listenableContent)
    });
    usePlaybackStore.setState(() => ({
      isReady: false
    }));
    throw error;
  }
};

const _withShouldSwitchToFreeCheck = (fn: (playbackInfo: PlaybackInfo) => void) => {
  return (playbackInfo?: PlaybackInfo) => {
    playbackInfo ??= usePlaybackStore.getState().currentPlaybackInfo;
    if (!playbackInfo) {
      return;
    }

    if (subscriptionStoreSelectors.getShouldSwitchToFree()) {
      subscriptionStoreActions.switchToFree();
    }

    fn(playbackInfo);
  };
};

const pressPlayPause = _withShouldSwitchToFreeCheck((playbackInfo: PlaybackInfo) => {
  playbackInfo.controls.pressPlayPause();
});

const pause = _withShouldSwitchToFreeCheck((playbackInfo: PlaybackInfo) => {
  playbackInfo.pause();
});

const resume = _withShouldSwitchToFreeCheck((playbackInfo: PlaybackInfo) => {
  playbackInfo.resume();
});

const onForwardClick = (playbackInfo?: PlaybackInfo) => {
  playbackInfo ??= usePlaybackStore.getState().currentPlaybackInfo;
  if (!playbackInfo) return;

  playbackInfo.controls.skipForwards();
  logAnalyticsEventOnForwardClick();
};

const onBackwardClick = (playbackInfo?: PlaybackInfo) => {
  playbackInfo ??= usePlaybackStore.getState().currentPlaybackInfo;
  if (!playbackInfo) return;

  playbackInfo.controls.skipBackwards();
  logAnalyticsEventOnBackwardClick();
};

const cleanup = () => {
  const currentState = usePlaybackStore.getState();
  if (currentState.listeningDependencies) {
    currentState.listeningDependencies.playbackInfo.pause();
    currentState.listeningDependencies.destroy();
  }
};

export const playbackStoreActions = {
  cleanup,
  initializeClassicExperience,
  initializeReaderBasedBookExperience,
  initializePdfExperience,
  onBackwardClick,
  onForwardClick,
  pressPlayPause,
  pause,
  resume
};
