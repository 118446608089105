import { getSDK } from 'modules/sdk/lib';
import { getExtensionId } from 'utils/extension';

import { useAuthStore } from '..';

export const setBillingDashboardUrl = async (billingDashboardUrl: string) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      user: { ...state.user, billingDashboardUrl }
    };
  });

  const sdk = await getSDK();
  const twentyMinutes = 1000 * 60 * 10 * 2;

  setTimeout(async () => {
    const extensionId = await getExtensionId();
    const billingDashboardUrl = await sdk.subscription.getBillingDashboardUrl(extensionId);
    setBillingDashboardUrl(billingDashboardUrl);
  }, twentyMinutes);
};
